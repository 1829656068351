import React from "react";

const WhyUs = () => (
  <div
    className="container-fluid"
    style={{ height: "325px", background: "#E7EDD9" }}
  >
    <div
      className="d-flex flex-column justify-content-center"
      style={{ height: "100%" }}
    >
      <h3 className="text-center">¿Por qué elegirnos?</h3>
      <div className="row justify-content-center mt-4">
        <div
          className="col-md-3"
          style={{ width: "240px", marginLeft: "20px" }}
        >
          <h4 className="text-center" style={{ width: "245px", margin: "0px" }}>
            Atención Personalizada
          </h4>
          <hr
            style={{
              border: "none",
              borderBottom: "3px solid #ADC9B8",
              width: "60px",
              margin: "19px auto",
            }}
          />
          <p className="text-center">
            Tu eres nuestro centro; por eso te escuchamos, nos involucramos y
            evaluamos todo lo necesario para llegar a la mejor solución.
          </p>
        </div>
        <div
          className="col-md-3"
          style={{ width: "245px", marginLeft: "20px" }}
        >
          <h4 className="text-center" style={{ width: "245px", margin: "0px" }}>
            Somos como tú
          </h4>
          <hr
            style={{
              border: "none",
              borderBottom: "3px solid #ADC9B8",
              width: "60px",
              margin: "19px auto",
            }}
          />
          <p className="text-center">
            Somos venezolanos, creemos en todo nuestro potencial y sabemos lo
            importante que es ser reconocidos por nuestros clientes y colegas.
          </p>
        </div>
        <div
          className="col-md-3"
          style={{ width: "245px", marginLeft: "20px" }}
        >
          <h4 className="text-center" style={{ width: "245px", margin: "0px" }}>
            Piezas exclusivas
          </h4>
          <hr
            style={{
              border: "none",
              borderBottom: "3px solid #ADC9B8",
              width: "60px",
              margin: "19px auto",
            }}
          />
          <p className="text-center">
            Cada solución que brindamos está pensada solo para ti, ajustada a
            todas y cada una de tus necesidades.
          </p>
        </div>
        <div
          className="col-md-3"
          style={{ width: "245px", marginLeft: "20px" }}
        >
          <h4 style={{ width: "245px", marginLeft: "60px" }}>Presupuesto</h4>
          <hr
            style={{
              border: "none",
              borderBottom: "3px solid #ADC9B8",
              width: "60px",
              margin: "19px auto",
            }}
          />
          <p className="text-center">
            Conocemos la realidad país y cuando podamos escucharte podremos
            conocer también tu realidad, por eso la solución que te brindaremos
            se ajustará a ti a la perfección.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default WhyUs;
