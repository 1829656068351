import React from "react";
import imageBackground from "./top_image.jpg";

const Carrousel = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{ paddingTop: "100px" }}
  >
    <img src={imageBackground} alt="medicalBG" className="img-fluid w-100" />
  </div>
);

Carrousel.propTypes = {};

Carrousel.defaultProps = {};

export default Carrousel;
