import React from "react";
import logo from "./logo-footer.png";
import { HashLink } from "react-router-hash-link";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -140;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const Footer = () => (
  <footer className="custom-footer mt-auto footer">
    <div className="container py-4">
      <div className="row">
        <div className="col-md-3">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="provider">
            <p>&copy;theavmdesign 2023</p>
          </div>
        </div>
        <div className="col-md-3 mt-5">
          <ul className="list-unstyled">
            <HashLink
              smooth
              to="/#nosotros"
              scroll={scrollWithOffset}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <p>Nosotros</p>
            </HashLink>
            <HashLink
              smooth
              to="/#servicios"
              scroll={scrollWithOffset}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <p>Servicios</p>
            </HashLink>
            <HashLink
              smooth
              to="/#contacto"
              scroll={scrollWithOffset}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <p>Contacto</p>
            </HashLink>
            <p>Términos y Condiciones</p>
          </ul>
        </div>
        <div className="col-md mt-5" style={{ width: "420px" }}>
          <p style={{ fontSize: "15px", fontWeight: "bold" }}>
            MEDICAL HEALTH SOLUTIONS
          </p>
          <p>info@medicalhealthsolution.com</p>
          <p className="bi bi-instagram"> medicalhealthsolutions</p>
          <p>Caracas, Venezuela</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
