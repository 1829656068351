import React from "react";
import { HashLink } from "react-router-hash-link";
import { Navbar, Nav } from "react-bootstrap";
import logo from "./logo-navbar.png";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -140;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const Navigation = () => (
  <Navbar bg="light" expand="lg" fixed="top" style={{ height: "109.4px" }}>
    <Navbar.Brand
      href="#home"
      className="logo-brand"
      style={{ paddingLeft: "231px" }}
    >
      <img
        src={logo}
        width="84"
        height="49.14"
        className="d-inline-block align-top"
        alt="Logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse
      id="basic-navbar-nav"
      className="justify-content-end"
      style={{ paddingRight: "238px" }}
    >
      <Nav className="mr-auto navbar-info">
        <Nav.Link href="/nosotros">
          <HashLink
            smooth
            to="/#nosotros"
            scroll={scrollWithOffset}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Nosotros
          </HashLink>
        </Nav.Link>
        <Nav.Link href="/servicios">
          <HashLink
            smooth
            to="/#servicios"
            scroll={scrollWithOffset}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Servicios
          </HashLink>
        </Nav.Link>
        <Nav.Link href="/contacto">
          <HashLink
            smooth
            to="/#contacto"
            scroll={scrollWithOffset}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Contacto
          </HashLink>
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Navigation;
