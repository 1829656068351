import React, { useRef } from "react";
import emailjs from "emailjs-com";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const Contact = () => {
  const form = useRef();
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
  };

  return (
    <div id="contacto" className="contact-container">
      <div className="container-fluid" style={{ backgroundColor: "#E7EDD9" }}>
        <div className="container py-5">
          <div className="container">
            <h3 className="text-center">Contacto</h3>
            <p
              className="text-center"
              style={{ color: "#003B4A", fontSize: "18px" }}
            >
              Te invitamos a que nos cuentes sobre ese proyecto que tienes en
              mente; y juntos podremos obtener la mejor solución.
            </p>
            <p
              className="text-center"
              style={{
                color: "#7AA5BA",
                fontSize: "18px",
                marginBottom: "25px",
              }}
            >
              PRONTO TE DAREMOS RESPUESTA.
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6">
              <form ref={form} onSubmit={handleOnSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="user_name"
                    placeholder="Nombre"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="user_email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Telefono"
                    id="phone"
                    name="user_phone"
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    label="Message"
                    name="message"
                    placeholder="Mensaje"
                    className="form-control"
                    id="message"
                    rows="5"
                  ></textarea>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary send-email">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
