import React from "react";
import visionImg from "./vision.jpg";
import misionImg from "./mision.jpg";

const Us = () => (
  <div id="nosotros" className="container" style={{ height: "506px" }}>
    <h3 className="text-center mt-5">NOSOTROS</h3>
    <p className="text-center">
      Somos Medical Health Solutions, asesor y proveedor de soluciones efectivas
      y eficaces que se adaptan a tus necesidades. <br /> <br />
      Te escuchamos atentamente para proporcionarte asesorías, equipos médicos,
      programas y cualquier equipamiento médico dentro de las instituciones que
      puedan ayudarte a desarrollar ese proyecto que estas emprendiendo.
    </p>

    <div className="row" style={{ marginTop: "60px" }}>
      <div className="col" style={{ marginLeft: "30px" }}>
        <div className="d-flex justify-content-center">
          <img
            src={misionImg}
            alt="mision"
            className="img-fluid"
            style={{ width: "446.25px", height: "201px" }}
          />
        </div>
      </div>
      <div className="col" style={{ marginRight: "30px" }}>
        <div className="d-flex justify-content-center">
          <img
            src={visionImg}
            alt="vision"
            className="img-fluid"
            style={{ width: "446.25px", height: "201px" }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Us;
