import React from "react";
import Navigation from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Carrousel from "./components/carrousel/carrousel";
import Us from "./components/us/us";
import WhyUs from "./components/whyUs/whyUs";
import Services from "./components/services/services";
import Contact from "./components/contact/contact";

function App() {
  return (
    <div className="app-container">
      <Navigation />
      <Carrousel />
      <Us />
      <WhyUs />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
