import React from "react";
import "./services.css";
import guia from "./guia.jpg";
import asesoramiento from "./asesoramiento.jpg";
import soluciones from "./soluciones.jpg";

const Services = () => {
  return (
    <div
      id="servicios"
      className="container"
      style={{ marginTop: "50px", marginBottom: "61px" }}
    >
      <h3 className="text-center">Servicios</h3>
      <p className="mt-3 text-center" style={{ fontSize: "18px" }}>
        En Medical Health Solutions nos encargamos de ayudarte a encontrarla y
        que este proceso
        <br /> sea lo más cómodo para ti.
      </p>
      <div className="row justify-content-center mt-4">
        <div className="col-md-4 mb-4">
          <div className="service" style={{ border: "3px solid #DDE9E3" }}>
            <img
              className="custom-shadow"
              src={guia}
              alt="Guia"
              style={{ width: "100%", height: "200px", objectFit: "cover" }}
            />
            <h4 className="mt-3">Guía</h4>
            <p style={{ marginBottom: "80px" }}>
              Te orientamos mientras resolvemos tus dudas, también te ofrecemos
              el mejor camino a seguir; seremos ese hogar familiar al que
              siempre podrás acudir cuando necesites.
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service" style={{ border: "3px solid #DDE9E3" }}>
            <img
              className="custom-shadow"
              src={asesoramiento}
              alt="Asesoramiento"
              style={{ width: "100%", height: "200px", objectFit: "cover" }}
            />
            <h4 className="mt-3">Asesoramiento</h4>
            <p style={{ marginBottom: "80px" }}>
              Nuestro principal foco eres tú, y es por esto que siempre te
              escucharemos atentamente para poder comprenderte y así brindarte
              las herramientas necesarias para satisfacer tus necesidades.
            </p>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="service" style={{ border: "3px solid #DDE9E3" }}>
            <img
              className="custom-shadow"
              src={soluciones}
              alt="Soluciones"
              style={{ width: "100%", height: "200px", objectFit: "cover" }}
            />
            <h4 className="mt-3">Soluciones</h4>
            <p style={{ marginBottom: "32px" }}>
              Queremos proporcionarte las mejores soluciones; para ello nos
              convertimos en tu aliado y consejero.
              <br />
              <br />
              Nos gusta escucharte y así brindarte la mejor solución que se
              adapte a tus necesidades; ya sean asesorías, equipos médicos o
              programas, cuentas con nosotros para ayudarte.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
